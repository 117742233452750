import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

const TechnicalMenu = (props) => {
  const s = require('./TechnicalMenu.module.scss');
  const { path } = props;

  return (
    <div className={s.TechnicalMenu}>
      <ul>
        <li>
          <a href="https://help.vodacom.co.za/personal/contactus?icmp=Internal%2FMDD%2FHelp&Support%2FContactUs" target="_blank" className={s.TechnicalLink}>Contact Us</a>
        </li>
        <li><Link href="/legal/privacy-policy" passHref><a className={s.TechnicalLink}>Privacy Policy</a></Link></li>
        <li><Link href="/legal/terms-of-use" passHref><a className={s.TechnicalLink && s.Last}>Terms &amp; Conditions</a></Link></li>
      </ul>
    </div>
  );
};

TechnicalMenu.propTypes = {
  path: PropTypes.string
};

export default TechnicalMenu;
